<template>
  <v-dialog
    v-model="typesEditorDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Vehicle Type</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="typeDataLocal.type"
                label="Type Name"
                dense
                outlined
                hide-details="auto"
                :error-messages="errors.type"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:types-editor-dialog', false)"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving"
          @click="saveType"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import axios from '@axios'

export default {
  props: {
    typesEditorDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    typeData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const saving = ref(false)
    const errors = ref([])
    const typeDataLocal = ref({})
    typeDataLocal.value = JSON.parse(JSON.stringify(props.typeData))
    const saveType = () => {
      const { id, type } = typeDataLocal.value
      saving.value = true
      if (typeDataLocal.value.id) {
        // update
        axios
          .put(`/vehicle-types/${typeDataLocal.value.id}`, {
            type,
            id,
          })
          .then(_ => {
            saving.value = false
            emit('saveType')
            emit('update:types-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        // new
        axios
          .post('/vehicle-types', { type })
          .then(_ => {
            saving.value = false
            emit('saveType')
            emit('update:types-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    watch(
      () => props.typesEditorDialog,
      () => {
        typeDataLocal.value = JSON.parse(JSON.stringify(props.typeData))
        errors.value = []
      },
    )

    return {
      // variables
      errors,
      typeDataLocal,
      saving,

      // function
      saveType,
    }
  },
}
</script>
